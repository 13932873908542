import React, { useState, useEffect } from "react"

export default function HelpTooltip({
  dir = "top right",
  tooltipStyle = {},
  tilte = '',
  value = 0,
  colors,
  // colors = 'red',
  children
}) {
  const [active, setActive] = useState(false)
  useEffect(() => {
    const closeTooltip = e => setActive(false)
    document.body.addEventListener("click", closeTooltip, false)
    return () => {
      document.body.removeEventListener("click", closeTooltip)
    }
  })

  const onClick = e => setActive(!active)
  const onMouseEnter = e => setActive(true)
  const onMouseLeave = e => setActive(false)

  let dirPosition
  switch (dir) {
    case "bottom right":
      dirPosition = {
        top: "calc(100% - 10px)",
        left: "calc(100% + 10px)",
      }
      break
    case "bottom center":
      dirPosition = {
        top: "100%",
        left: "50%",
        transform: "translate(-50%, 0px)",
        maxWidth: "70vw",
        width: "240px",
      }
      break
    case "top right":
    default:
      dirPosition = {
        bottom: "calc(100% - 10px)",
        left: "calc(100% + 10px)",
      }
      break
  }

  return (
    <span
      css={{
        position: "relative",
      }}
    >
      <div
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </div>
      {
        children ? (
          <div
            css={{
              position: "absolute",
              width: "160px",
              zIndex: "1000",
              padding: "8px",
              marginTop: "5px",
              color: "#000000",
              fontSize: "12px",
              border: "1px solid #eeeeee",
              borderRadius: "2px",
              backgroundColor: "#ffffff",
              boxShadow: "0 5px 8px rgba(0,0,0,0.12)",
            }}
            style={{
              display: active ? "block" : "none",
              ...dirPosition,
              ...tooltipStyle,
            }}
          >
            <div className="flex flex-row">
              <div className="rounded-full w-[12px] h-[12px] mr-1" style={{ backgroundColor: colors }}></div>
              <p className="text-[14px] mb-0">{tilte}</p >
            </div>
            <p className="text-[18px] mb-0">{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} คน</p>
          </div>
        ) : null
      }
    </span >
  )
}
