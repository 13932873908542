import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Tabs, Tab, Typography, Box
} from '@mui/material';

import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { Table, Tag, Space, Select } from 'antd';
import Button from '@mui/material/Button';
import datacity from './datacity.json';
import '../index.css';

const { Option } = Select;


const Filter = (props) => {
    const [open, setOpen] = useState(true)
    const [data, setData] = useState({
        alljon: 0,
        krt: 0,
        vec: 0,
        tps: 0,
        target_group: 0,
        table: []
    })

    useEffect(() => {

    }, [])

    return (
        <div className="flex flex-row justify-around" >
            <div className="flex justify-center flex-col bg-[#fff] p-2 m-2 mt-0 rounded-lg w-[600px]">
                <div className="flex flex-col mx-auto md:flex-row " >
                    <p className='text-center text-[#038967] font-bold text-lg mt-1 mr-2'>ค้นหาพื้นที่ดำเนินงาน</p>
                    <Select
                        showSearch
                        placeholder="เลือกจังหวัด"
                        defaultValue="ทั้งหมด"
                        value={props.filter.province_name}
                        style={{ width: 180, margin: 4 }}
                        onChange={(val) => {
                            props.onChange(val, val === "ทั้งหมด" ? 'country' : 'province')
                        }}
                    >
                        <Option value={'ทั้งหมด'}>ทั้งหมด</Option>
                        {
                            [...new Set(datacity.map(x => x.province))]
                                .filter(x => x !== 'ไม่ระบุ')
                                .sort()
                                .map(x => <Option value={x}>{x}</Option>)
                        }
                    </Select>
                    <Select
                        showSearch
                        value={props.filter.city_name}
                        placeholder="เลือกอำเภอ" style={{ width: 180, margin: 4 }} disabled={props.filter.view === 'country'}
                        onChange={(val) => {
                            if (val === "ทั้งหมด") {
                                props.onChange(val, 'country')
                            } else {
                                props.onChange(val, 'city')
                            }

                        }}
                    >
                        {
                            [...new Set(datacity.filter(x => props.filter.province_name === x.province || props.filter.province_name === null).map(x => x.district))]
                                .sort()
                                .filter(x => x !== 'ไม่ระบุ')
                                .map(x => <Option value={x}>{x}</Option>)
                        }
                    </Select>
                </div>
            </div>
        </div >
    )
}

export default Filter


