import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, IconButton } from '@mui/material';

import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import EventIcon from '@mui/icons-material/Event';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Table, Tag, Space, Select, Modal, Collapse, Popover } from 'antd';
import Button from '@mui/material/Button';
import Filter from './Filter';
import HelpTooltip from './HelpTooltip';
import InfoIcon from '@mui/icons-material/Info';
import imageChildrenOOSC from '../../../images/children_oosc.png';
import imageSchool from '../../../images/school.png';
import image1 from '../../../images/ทุนเสมอภาค.png';
import image2 from '../../../images/ทุนครูรักษ์ถิ่น.png';
import image3 from '../../../images/ทุนนวัตกรรม.png';
import image4 from '../../../images/ทุนพระกนิษฐา.png';
import image5 from '../../../images/ทุนพัฒนาทักษะอาชีพ.png';
import CloseIcon from '@mui/icons-material/Close';
import '../index.css';
const { Column, ColumnGroup } = Table;
const { Panel } = Collapse;


const columns_1 = [
  {
    title: 'จำนวนนักเรียนที่ได้รับการช่วยเหลอ ทุน/โครงการ กสศ.',
    children: [
      {
        title: 'ทุนเสมอภาค',
        dataIndex: 'alljon',
        key: 'alljon',
        align: 'center',
        render(text, record) {
          return {
            props: {
              // style: { background: `hsl(118, 40%, ${50 - (30 * (text / record.sum)) + 50}%)`, textAlign: "right" }
              style: { textAlign: "right" }
            },
            children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
          };
        },
        width: 65,
        sorter: (a, b) => a.alljon - b.alljon,
      },
      {
        title: 'ครูรัก(ษ์)ถิ่น',
        dataIndex: 'krt',
        key: 'krt',
        align: 'center',
        render(text, record) {
          return {
            props: {
              style: { textAlign: "right" }
            },
            children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
          };
        },
        width: 60,
        sorter: (a, b) => a.krt - b.krt,
      },
      {
        title: 'นวัตกรรมสายอาชีพ',
        dataIndex: 'vec',
        key: 'vec',
        width: 85,
        align: 'center',
        render(text, record) {
          return {
            props: {
              style: { textAlign: "right" }
            },
            children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
          };
        },
        sorter: (a, b) => a.vec - b.vec,
      },
      {
        title: 'ทุนพระกนิษฐา',
        dataIndex: 'tps',
        key: 'tps',
        width: 70,
        align: 'center',
        render(text, record) {
          return {
            props: {
              style: { textAlign: "right" }
            },
            children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
          };
        },
        sorter: (a, b) => a.tps - b.tps,
      },
      {
        title: 'ทุนพัฒนาอาชีพ',
        dataIndex: 'target_group',
        key: 'target_group',
        width: 60,
        align: 'center',
        render(text, record) {
          return {
            props: {
              style: { textAlign: "right" }
            },
            children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
          };
        },
        sorter: (a, b) => a.target_group - b.target_group,
      },
      {
        title: 'รวม',
        dataIndex: 'sum',
        key: 'sum',
        width: 60,
        align: 'center',
        render(text, record) {
          return {
            props: {
              style: { textAlign: "right" }
            },
            children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
          };
        },
        sorter: (a, b) => a.sum - b.sum,
      },
    ],
  },

]


const columns_2 = [
  {
    title: 'จำนวนนักเรียนที่ได้รับการช่วยเหลือ ทุน/โครงการ กสศ.',
    children: [
      {
        title: 'เด็ก/เยาวชนที่ไม่มีข้อมูลในระบบการศึกษา',
        children: [

          {
            title: '20 จังหวัด',
            dataIndex: 'province20',
            key: 'province20',
            width: 40,
            align: 'center',
            render(text, record) {
              return {
                props: {
                  style: { textAlign: "right", borderLeft: '1px solid #16a34a', backgroundColor: '#f0fdf4' }
                },
                children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
              };
            },
            sorter: (a, b) => a.province20 - b.province20,
          },
          {
            title: 'แผนครูและเด็กนอกระบบ',
            dataIndex: 'plan_teaher',
            key: 'plan_teaher',
            width: 60,
            align: 'center',
            render(text, record) {
              return {
                props: {
                  style: { textAlign: "right", backgroundColor: '#f0fdf4' }
                },
                children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
              };
            },
            sorter: (a, b) => a.plan_teaher - b.plan_teaher,
          },
          {
            title: 'รวม',
            dataIndex: 'sum_oosc',
            key: 'sum_oosc',
            width: 40,
            align: 'center',
            render(text, record) {
              return {
                props: {
                  style: { textAlign: "right", borderRight: '1px solid #16a34a', backgroundColor: '#f0fdf4' }
                },
                children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
              };
            },
            sorter: (a, b) => a.sum_oosc - b.sum_oosc,
          },
        ],
      },
      {
        title: 'เด็กปฐมวัย',
        dataIndex: 'ecd',
        key: 'ecd',
        width: 70,
        align: 'center',
        render(text, record) {
          return {
            props: {
              style: { textAlign: "right" }
            },
            children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
          };
        },
        sorter: (a, b) => a.ecd - b.ecd,
      },

    ],
  },

]

const columns_3 = [
  {
    title: 'TSQP',
    dataIndex: 'tsqp',
    key: 'tsqp',
    width: 70,
    align: 'center',
    render(text, record) {
      return {
        props: {
          style: { textAlign: "right" }
        },
        children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
      };
    },
    sorter: (a, b) => a.tsqp - b.tsqp,
  },

]

const { Option } = Select;

function TabPanel(props) {
  const svgRef = React.useRef(null);

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const handleTitle = (filter) => {
  if (filter?.view === 'province') {
    return 'อำเภอ'
  } else if (filter?.view === 'city') {
    return 'ตำบล'
  } else {
    return 'จังหวัด'
  }
}
//  title: handleTitle(filter.view)'จังหวัด',

const g = (data, filter) => {
  let _data = [...data]
  let alljon = 0
  let krt = 0
  let vec = 0
  let tps = 0
  let target_group = 0
  let sp = 0
  let oosc_all = 0
  let school = 0
  let tsqp = 0
  let province20 = 0
  let sum_oosc = 0
  let plan_teaher = 0
  let ecd = 0
  let disablility = 0
  let welfare = 0
  let key = 'province_name'
  let _filter = () => { return true }
  if (filter.view === "province") {
    _filter = (a) => {
      return a.province_name == filter.province_name
    }
    key = 'city_name'
  } else if (filter.view === "city") {
    _filter = (a) => {
      return (a.province_name == filter.province_name && a.city_name == filter.city_name)
    }
    key = 'tumbon_name'
  }
  let OJB = {}
  _data
    .filter(_filter)
    .forEach((element, ixx) => {
      if (!OJB[element[key]]) {
        OJB[element[key]] = {
          key: element[key],
          alljon: 0,
          krt: 0,
          vec: 0,
          tps: 0,
          target_group: 0,
          sum: 0,
          abe: 0,
          oosc: 0,
          tsqp: 0,
          sum_demo: 0,
          province20: 0,
          sum_oosc: 0,
          plan_teaher: 0,
          ecd: 0,
          disablility: 0,
          welfare: 0
        }
      }

      alljon += (element.exjon)
      krt += (element.krt)
      vec += (element.vec)
      tps += (element.tps)
      target_group += (element.target_group)
      sp += (element.total)
      school += (element.school)
      oosc_all += (element.total_oosc ? element.total_oosc : 0)
      tsqp += (element.tsqp ? element.tsqp : 0)
      province20 += (element.province20 ? element.province20 : 0)
      // sum_oosc += (element.sum_oosc ? element.sum_oosc : 0)
      plan_teaher += (element.plan_teaher ? element.plan_teaher : 0)
      ecd += (element.abe ? element.abe : 0)
      disablility += (element.disablility ? element.disablility : 0)
      welfare += (element.welfare ? element.welfare : 0)
      OJB[element[key]].alljon += (element.exjon)
      OJB[element[key]].krt += element.krt
      OJB[element[key]].vec += element.vec
      OJB[element[key]].tps += element.tps
      OJB[element[key]].target_group += element.target_group
      OJB[element[key]].sum += (element.exjon + element.krt + element.vec + element.tps + element.target_group)
      OJB[element[key]].abe += (element.abe ? element.abe : 0)
      OJB[element[key]].oosc += (element.oosc ? element.oosc : 0)
      OJB[element[key]].tsqp += (element.tsqp ? element.tsqp : 0)
      OJB[element[key]].province20 += (element.province20 ? element.province20 : 0)
      OJB[element[key]].sum_oosc += ((element.province20 ? element.province20 : 0) + (element.plan_teaher ? element.plan_teaher : 0))
      // (element.sum_oosc ? element.sum_oosc : 0)
      OJB[element[key]].plan_teaher += (element.plan_teaher ? element.plan_teaher : 0)
      OJB[element[key]].ecd += (element.abe ? element.abe : 0)
      OJB[element[key]].disablility += (element.disablility ? element.disablility : 0)
      OJB[element[key]].welfare += (element.welfare ? element.welfare : 0)
      OJB[element[key]].sum_demo += (element.tsqp ? element.tsqp : 0)
    });
  return {
    krt,
    vec,
    tps,
    target_group,
    alljon,
    all: sp + oosc_all,
    sp: sp,
    oosc_all: oosc_all,
    schol: school,
    tsqp,
    province20,
    sum_oosc,
    plan_teaher,
    ecd,
    disablility,
    welfare,
    table: Object.keys(OJB).map(x => OJB[x])
  }

}

const StaticBar = ({ data }) => {
  let sum_all = data.sp + data.oosc_all
  let sp_percent = Math.floor(((data.sp / sum_all) * 100))
  let dis_percent = 0

  let oosc_percent = 100 - Math.floor(((data.sp / sum_all) * 100))
  if (sum_all === 0) return null

  return (
    <div className="flex flex-row w-full" >
      <div style={{ width: `${sp_percent}%` }}>
        <div className={`flex flex-col`}>
          <HelpTooltip
            value={(data.sp)}
            tilte="เด็กนักเรียนในระบบการศึกษาขั้นพื้นฐาน"
            dir="bottom center"
            colors="#3EC06A"
          >
            <>
              <div className="bg-[#3EC06A] h-10 p-2">
                <span className='font-bold text-left text-white font-[16px]'>{data.sp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} คน</span>
              </div>
            </>
          </HelpTooltip>
          <span className='font-bold text-left text-ellipsis truncate pr-2'>เด็กนักเรียนในระบบการศึกษาขั้นพื้นฐาน</span>
        </div>
      </div>

      <div style={{ width: `${oosc_percent}%` }}>
        <div className={`flex flex-col`}>
          <HelpTooltip
            description={'sss'}
            value={data.oosc_all}
            tilte="เด็กที่ไม่มีข้อมูลในระบบการศึกษา"
            dir="bottom center"
            colors="#DFC050"
          >
            <>
              <div className="bg-[#DFC050] h-10 p-2">
                <span className='font-bold text-left text-white font-[16px]'>{data.oosc_all.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} คน</span>
              </div>
            </>
          </HelpTooltip>
          <span className='font-bold text-left text-ellipsis truncate pr-2' >เด็กที่ไม่มีข้อมูลในระบบการศึกษา</span>
        </div>
      </div>

    </div >
  )
}

const Sidetab = (props) => {
  const [ismodalUnknow, setModalUnknow] = useState(false)
  const [open, setOpen] = useState(true)
  const [ismodal, setModal] = useState(false)
  const [data, setData] = useState({
    alljon: 0,
    krt: 0,
    vec: 0,
    tps: 0,
    target_group: 0,
    all: 0,
    sp: 0,
    oosc_all: 0,
    schol: 0,
    tsqp: 0,
    disablility: 0,
    welfare: 0,
    table: []
  })

  const [value, setValue] = React.useState(0);

  useEffect(() => {
    setData(g(props.data, props.filter))
    // setNotic(response.notic)
  }, [props.data, props.filter])


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={`absolute bottom-0 right-0 lg:top-0 lg:right-0 duration-700 ${props.open ? 'translate-x-0 translate-y-0' : 'translate-y-full translate-x-0 lg:translate-y-0 lg:translate-x-full'} w-screen lg:w-1/2 lg:h-[100vh] z-[1]`}>
      <div className={`flex absolute h-[40px] -mt-[40px] lg:-ml-[140px] lg:w-[240px] right-0 lg:left-0 lg:top-[220px] bg-green-800 lg:-rotate-90 p-1 px-8 lg:p-1 rounded-t-lg`}
        onClick={() => {
          props.setOpen(!props.open)
        }}>
        <svg className='ml-auto' version="1.0" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 32 32">
          <g transform="translate(0,32) scale(0.1,-0.1)" fill="#ffffff" stroke="none">
            <path d="M125 298 c-3 -8 -4 -75 -3 -149 l3 -134 35 0 35 0 0 145 0 145 -33 3 c-20 2 -34 -2 -37 -10z" />
            <path d="M230 125 l0 -116 38 3 37 3 0 110 0 110 -37 3 -38 3 0 -116z" />
            <path d="M14 146 c-3 -7 -4 -40 -2 -72 l3 -59 38 -3 37 -3 0 75 0 76 -35 0 c-21 0 -38 -6 -41 -14z" />
          </g>
        </svg>
        <p className="m-1 mr-auto text-white text-xl text-center">
          ขยายแผนที่
        </p>
      </div>

      <div className='w-[100%] lg:flex lg:h-[100%]'>

        <Modal
          width={800}
          visible={ismodal}
          // title="แหล่งที่มาของข้อมูล"
          onOk={() => setModal(false)}
          onCancel={() => setModal(false)}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <div>
            {
              <div>
                <span className='text-lg font-bold'>แหล่งที่มาข้อมูล</span><br />
                <span className='pl-6'>ข้อมูลการช่วยเหลือกลุ่มเป้าหมายในแต่ละโครงการของ กสศ. ประกอบด้วย</span><br />
                <br />
                <span className='text-lg font-bold'>ข้อมูลนักเรียนในระบบการศึกษา</span><br />
                <span className='pl-6'>ข้อมูลจำนวนนักเรียนในแต่ละสังกัดปีการศึกษา 2564 </span><br />
                <Collapse style={{ marginLeft: '15px' }} ghost accordion={true} expandIcon={() => { return <></> }}>
                  <Panel header={<a>(คลิกเพื่อดูรายชื่อสังกัดที่นำมาใช้ในการประมวลผล)</a>} key="1" showArrow={false}>
                    <ul className='bg-[#f0fdf4] rounded-md py-4'>
                      <li className='pl-6'>- สำนักงานคณะกรรมการการศึกษาขั้นพื้นฐาน</li>
                      <li className='pl-6'>- สำนักงานคณะกรรมการส่งเสริมการศึกษาเอกชน</li>
                      <li className='pl-6'>- สำนักงานส่งเสริมการศึกษานอกระบบและการศึกษาตามอัธยาศัย</li>
                      <li className='pl-6'>- สำนักงานคณะกรรมการการอาชีวศึกษา</li>
                      <li className='pl-6'>- กระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม</li>
                      <li className='pl-6'>- โรงเรียนมหิดลวิทยานุสรณ์</li>
                      <li className='pl-6'>- กรมส่งเสริมการปกครองท้องถิ่น</li>
                      <li className='pl-6'>- สำนักการศึกษา กรุงเทพมหานคร</li>
                      <li className='pl-6'>- สำนักการแพทย์ กรุงเทพมหานคร</li>
                      <li className='pl-6'>- สำนักการศึกษา เมืองพัทยา</li>
                      <li className='pl-6'>- ศูนย์ฝึกพาณิชย์นาวี</li>
                      <li className='pl-6'>- สถาบันการบินพลเรือน</li>
                      <li className='pl-6'>- กรมกิจการเด็กและเยาวชน</li>
                      <li className='pl-6'>- สถาบันพระบรมราชชนก</li>
                      <li className='pl-6'>- สถาบันวิชาการป้องกันประเทศ กองบัญชาการกองทัพไทย</li>
                      <li className='pl-6'>- กองทัพบก</li>
                      <li className='pl-6'>- กองทัพเรือ</li>
                      <li className='pl-6'>- กองทัพอากาศ</li>
                      <li className='pl-6'>- มหาวิทยาลัยการกีฬาแห่งชาติ</li>
                      <li className='pl-6'>- สถาบันบัณฑิตพัฒนศิลป์</li>
                      <li className='pl-6'>- สำนักงานพระพุทธศาสนาแห่งชาติ</li>
                      <li className='pl-6'>- โรงเรียนนายร้อยตำรวจ</li>
                      <li className='pl-6'>- กองบัญชาการตำรวจตระเวนชายแดน</li>
                    </ul>
                  </Panel>
                </Collapse>
                <br />
                <span className='text-lg font-bold'>ข้อมูลจำนวนเด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา (อายุ 3 - 17 ปี)</span><br />
                <span className='pl-6'>(1) ข้อมูลทะเบียนราษฎร์ ปี 2564 (ได้จากข้อมูลทะเบียนราษฎ์ที่ได้รับมาในปี 2563 นำมารวมกับข้อมูลนักเรียนที่ได้รับจากสำนักปลัดกระทรวงศึกษาปี 2563 จากนั้นเลื่อนอายุขึ้นมา 1 ปี)</span><br />
                <span className='pl-6'>(2) ข้อมูลจำนวนนักเรียนในแต่ละสังกัดปีการศึกษา 2564</span><br />
                <span className='pl-6'>(3) นำข้อมูลในข้อที่ (1) หักลบกับ (2) จะได้จำนวนเด็กที่ไม่มีข้อมูลในระบบการศึกษา ปี 2564</span><br />
                <br />
                <span className='text-lg font-bold'>ข้อมูลจำนวนสถานศึกษา</span><br />
                <span className='pl-6'>ข้อมูลจำนวนสถานศึกษาในแต่ละสังกัดปีการศึกษา 2564 </span><br />
                <Collapse style={{ marginLeft: '15px' }} ghost accordion={true} expandIcon={() => { return <></> }}>
                  <Panel header={<a>(คลิกเพื่อดูรายชื่อสังกัดที่นำมาใช้ในการประมวลผล)</a>} key="1" showArrow={false}>
                    <ul className='bg-[#f0fdf4] rounded-md py-4'>
                      <li className='pl-6'>- สำนักงานคณะกรรมการการศึกษาขั้นพื้นฐาน</li>
                      <li className='pl-6'>- สำนักงานคณะกรรมการส่งเสริมการศึกษาเอกชน</li>
                      <li className='pl-6'>- สำนักงานส่งเสริมการศึกษานอกระบบและการศึกษาตามอัธยาศัย</li>
                      <li className='pl-6'>- สำนักงานคณะกรรมการการอาชีวศึกษา</li>
                      <li className='pl-6'>- กระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม</li>
                      <li className='pl-6'>- โรงเรียนมหิดลวิทยานุสรณ์</li>
                      <li className='pl-6'>- กรมส่งเสริมการปกครองท้องถิ่น</li>
                      <li className='pl-6'>- สำนักการศึกษา กรุงเทพมหานคร</li>
                      <li className='pl-6'>- สำนักการแพทย์ กรุงเทพมหานคร</li>
                      <li className='pl-6'>- สำนักการศึกษา เมืองพัทยา</li>
                      <li className='pl-6'>- ศูนย์ฝึกพาณิชย์นาวี</li>
                      <li className='pl-6'>- สถาบันการบินพลเรือน</li>
                      <li className='pl-6'>- กรมกิจการเด็กและเยาวชน</li>
                      <li className='pl-6'>- สถาบันพระบรมราชชนก</li>
                      <li className='pl-6'>- สถาบันวิชาการป้องกันประเทศ กองบัญชาการกองทัพไทย</li>
                      <li className='pl-6'>- กองทัพบก</li>
                      <li className='pl-6'>- กองทัพเรือ</li>
                      <li className='pl-6'>- กองทัพอากาศ</li>
                      <li className='pl-6'>- มหาวิทยาลัยการกีฬาแห่งชาติ</li>
                      <li className='pl-6'>- สถาบันบัณฑิตพัฒนศิลป์</li>
                      <li className='pl-6'>- สำนักงานพระพุทธศาสนาแห่งชาติ</li>
                      <li className='pl-6'>- โรงเรียนนายร้อยตำรวจ</li>
                      <li className='pl-6'>- กองบัญชาการตำรวจตระเวนชายแดน</li>
                    </ul>
                  </Panel>
                </Collapse>
                <br />
                <span className='text-lg font-bold'>ทุนสร้างโอกาส</span><br />
                <span className='pl-6'><b>ทุนเสมอภาค</b> ข้อมูลจำนวนเด็กนักเรียนที่ได้รับความช่วยเหลือใน 6 สังกัด ได้แก่ สพฐ. อปท. ตชด. พศ. สช. และ กทม. ปีการศึกษา 2566 </span><br />
                <span className='pl-6'><b>ทุนครูรัก(ษ์)ถิ่น</b> ข้อมูลจำนวนเด็กนักเรียนที่ได้รับความช่วยเหลือในปีการศึกษา 2566 </span><br />
                <span className='pl-6'><b>ทุนนวัตกรรมสายอาชีพ</b> ข้อมูลจำนวนเด็กนักเรียนที่ได้รับความช่วยเหลือในปีการศึกษา 2566 </span><br />
                <span className='pl-6'><b>ทุนพระกนิษฐา</b> ข้อมูลจำนวนเด็กนักเรียนที่ได้รับความช่วยเหลือในปีการศึกษา 2566 </span><br />
                <span className='pl-6'><b>ทุนพัฒนาอาชีพและนวัตกรรมที่ใช้ชุมชนเป็นฐาน</b> ข้อมูลจำนวนกลุ่มเป้าหมายที่ได้รับความช่วยเหลือในปีการศึกษา 2564</span>
                <br />
                <span className='text-lg font-bold'>นวัตกรรมต้นแบบ</span><br />
                <span className='pl-6'><b>เด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา </b> ข้อมูลการสำรวจแผนงาน 20 จังหวัดเสมอภาค ปี 2563</span><br />
                <span className='pl-6'><b>เด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา</b> ข้อมูลการสำรวจแผนงานครูและเด็กนอกระบบ ปี 2563 </span><br />
                <span className='pl-6'><b>เด็กปฐมวัยยากจนใน ศพด.</b> ข้อมูลจำนวนเด็กปฐมวัยที่ได้รับความช่วยเหลือในพื้นที่ 20 จังหวัดเสมอภาค 	ในปีการศึกษา 2563 </span><br />
                <span className='pl-6'><b>โครงการ TSQP </b> ข้อมูลจำนวนโรงเรียนที่เข้าร่วมโครงการ ปีการศึกษา 2563 </span><br />
                <br />
                <span className='text-lg font-bold text-[#15803d]'>*ประมวลผลล่าสุด ณ เดือน มิ.ย. 2566</span><br />
              </div>
            }
          </div>
        </Modal>

        <Modal
          width={750}
          visible={ismodalUnknow}
          // title="แหล่งที่มาของข้อมูล"
          onOk={() => setModalUnknow(false)}
          onCancel={() => setModalUnknow(false)}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <div>
            {
              <div>
                <span className='text-lg font-bold'>หมายเหตุ</span><br />
                <span>ไม่ระบุ หมายถึง ข้อมูลที่ไม่สามารถประมวลผลเพื่อจำแนกตามจังหวัด อำเภอ ตำบล ได้ เนื่องจาก</span><br />
                <span className='pl-6'>1. ข้อมูลตั้งต้นไม่มีการระบุข้อมูลในส่วนของจังหวัด อำเภอ ตำบล เข้ามา</span><br />
                <span className='pl-6'>2. ข้อมูลตั้งต้นนั้นข้อมูลในส่วนของจังหวัด อำเภอ ตำบล ไม่ถูกต้อง</span><br />
              </div>
            }
          </div>
        </Modal>

        <div className={`h-[100vh] w-[100%] pt-[90px] lg:pt-0 lg:w-full lg:h-[100%] z-[1] bg-[#f7f9fe] overflow-y-auto`}>
          <div className='px-1 lg:pt-[100px] text-cennter content-center'>
            <div className='lg:hidden text-right'>
              <IconButton className='p-0' size="large" aria-label="delete" onClick={() => props.setOpen(!props.open)}>
                <CloseIcon />
              </IconButton>
            </div>
            <p className='text-center text-[#038967] font-bold mb-6 text-xl lg:text-3xl'>การดำเนินงานช่วยเหลือกลุ่มเป้าหมายของ กสศ. ในพื้นที่ของท่าน</p>

            <Filter
              filter={props.filter}
              onChange={props.updateFilter}
            />

            <div>
              <div className='flex flex-row'>
                <p className='text-[#333] font-bold text-md lg:text-lg m-0 mt-2 mb-1'>จำนวนเด็กและเยาวชนวัยเรียนในพื้นที่
                  {props.filter.province_name && props.filter.province_name !== 'ทั้งหมด' ? (props.filter.city_name ? ' จ.' + props.filter.province_name + " อ." + props.filter.city_name : ' จ.' + props.filter.province_name) : ' ทั้งหมด'}
                  {" "}<span className='text-blue-500'>{data.all.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> คน {`(`} <span className='text-blue-500'>{data.schol.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> โรงเรียน){``} </p>
                <Popover
                  content={
                    <div>
                      <p className='text-xl'> ข้อมูลการช่วยเหลือกลุ่มเป้าหมายของ กสศ. ในพื้นที่ของท่าน</p>
                      <p className='ml-[15px]'>  ข้อมูลจำนวนนักเรียนในแต่ละสังกัดปีการศึกษา 2564</p>
                      <Collapse style={{ marginLeft: '15px' }} ghost accordion={true} expandIcon={() => { return <></> }}>
                        <Panel header={<a>(คลิกเพื่อดูรายชื่อสังกัดที่นำมาใช้ในการประมวลผล)</a>} key="1" showArrow={false}>
                          <ul className='bg-[#f0fdf4] rounded-md py-4'>
                            <li className='pl-6'>- สำนักงานคณะกรรมการการศึกษาขั้นพื้นฐาน</li>
                            <li className='pl-6'>- สำนักงานคณะกรรมการส่งเสริมการศึกษาเอกชน</li>
                            <li className='pl-6'>- สำนักงานส่งเสริมการศึกษานอกระบบและการศึกษาตามอัธยาศัย</li>
                            <li className='pl-6'>- สำนักงานคณะกรรมการการอาชีวศึกษา</li>
                            <li className='pl-6'>- กระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม</li>
                            <li className='pl-6'>- โรงเรียนมหิดลวิทยานุสรณ์</li>
                            <li className='pl-6'>- กรมส่งเสริมการปกครองท้องถิ่น</li>
                            <li className='pl-6'>- สำนักการศึกษา กรุงเทพมหานคร</li>
                            <li className='pl-6'>- สำนักการแพทย์ กรุงเทพมหานคร</li>
                            <li className='pl-6'>- สำนักการศึกษา เมืองพัทยา</li>
                            <li className='pl-6'>- ศูนย์ฝึกพาณิชย์นาวี</li>
                            <li className='pl-6'>- สถาบันการบินพลเรือน</li>
                            <li className='pl-6'>- กรมกิจการเด็กและเยาวชน</li>
                            <li className='pl-6'>- สถาบันพระบรมราชชนก</li>
                            <li className='pl-6'>- สถาบันวิชาการป้องกันประเทศ กองบัญชาการกองทัพไทย</li>
                            <li className='pl-6'>- กองทัพบก</li>
                            <li className='pl-6'>- กองทัพเรือ</li>
                            <li className='pl-6'>- กองทัพอากาศ</li>
                            <li className='pl-6'>- มหาวิทยาลัยการกีฬาแห่งชาติ</li>
                            <li className='pl-6'>- สถาบันบัณฑิตพัฒนศิลป์</li>
                            <li className='pl-6'>- สำนักงานพระพุทธศาสนาแห่งชาติ</li>
                            <li className='pl-6'>- โรงเรียนนายร้อยตำรวจ</li>
                            <li className='pl-6'>- กองบัญชาการตำรวจตระเวนชายแดน</li>
                          </ul>
                        </Panel>
                      </Collapse>
                      <p className='text-xl'> ข้อมูลจำนวนเด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา (อายุ 3 - 17 ปี)</p>
                      <p className='ml-[15px]'>  (1) ข้อมูลทะเบียนราษฎร์ ปี 2564 (ได้จากข้อมูลทะเบียนราษฎ์ที่ได้รับมาในปี 2563 นำมารวมกับข้อมูลนักเรียนที่ได้รับจากสำนักปลัดกระทรวงศึกษาปี 2563 จากนั้นเลื่อนอายุขึ้นมา 1 ปี)</p>
                      <p className='ml-[15px]'> (2) ข้อมูลจำนวนนักเรียนในแต่ละสังกัดปีการศึกษา 2564</p>
                      <p className='ml-[15px]'> (3) นำข้อมูลในข้อที่ (1) หักลบกับ (2) จะได้จำนวนเด็กที่ไม่มีข้อมูลในระบบการศึกษา ปี 2564</p>

                    </div>
                  }
                  title="ข้อมูลนักเรียนในระบบการศึกษา และ ข้อมูลจำนวนเด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา"
                  placement="bottom">
                  <button style={{ margin: 1, padding: 1 }}>
                    <div className='flex flex-row '>
                      < InfoOutlinedIcon fontSize={'18px'} className='flex self-center mx-1' />
                    </div>
                  </button>
                </Popover>
              </div>

              <StaticBar data={data} />

              <div>
                <p className='text-[#038967] font-bold text-xl lg:text-2xl m-0 mt-8 text-center'>ข้อมูลการช่วยเหลือกลุ่มเป้าหมายของ กสศ. ในพื้นที่ของท่าน </p>
              </div>
              <div className="flex flex-row mb-2 justify-center " >
                <Button
                  onClick={() => {
                    props.updatePageAndTap(1, 'page')
                    // setPage(1)
                  }} style={{ backgroundColor: props.filter.page === 1 ? '#0063cc' : '#999', color: '#fff', margin: 4, fontSize: '18px' }}>ทุนสร้างโอกาส</Button>

                <Button
                  onClick={() => {
                    props.updatePageAndTap(2, 'page')
                    // setPage(2)
                  }}
                  style={{ backgroundColor: props.filter.page === 2 ? '#0063cc' : '#999', color: '#fff', margin: 4, fontSize: '18px' }}>นวัตกรรมต้นแบบ</Button>
              </div>
              <div className='flex flex-row justify-end text-[16px] mr-4 just'><EventIcon fontSize='18px' className='my-1' />ประมวลผลล่าสุด ณ เดือน มิ.ย. 2566</div>
              {
                props.filter.page === 2 && <>
                  <div className="flex flex-row flex-row" >
                    <div onClick={() => {
                      props.updatePageAndTap(1, 'tap')
                      // setTap(1)
                    }} style={{
                      backgroundColor: '#fff', fontSize: '16px', padding: '8px', borderTopLeftRadius: '12px',
                      "borderWidth": "0px 0px 5px 0px",
                      borderColor: props.filter.tap === 1 ? "green" : "#fff",
                    }}>
                      <p style={{ color: props.filter.tap === 1 ? 'green' : '#333', margin: 0 }}>โครงการจัดการศึกษาเชิงพื้นที่ </p>
                    </div>
                    <div
                      onClick={() => {
                        props.updatePageAndTap(2, 'tap')
                        // setTap(2)
                      }}
                      style={{
                        backgroundColor: '#fff', fontSize: '16px', padding: '8px', color: props.filter.tap === 2 ? 'green' : '#333', borderTopRightRadius: '12px',
                        "borderWidth": "0px 0px 5px 0px",
                        borderColor: props.filter.tap === 2 ? "green" : "#fff",
                      }}>
                      <p style={{ color: props.filter.tap === 2 ? 'green' : '#333', margin: 0 }}>โครงการ TSQP </p>
                    </div>
                  </div>
                </>
              }
              <div className="flex flex-row flex-row overflow-auto" >


                <div className="flex flex-col rounded-lg w-full shadow-lg bg-[#fff] p-2" >
                  <div className="flex flex-row w-full justify-around " >
                    {
                      props.filter.page === 1 && <>
                        <div className="flex flex-row bg-[#fff] p-1 mt-0 content-center ">
                          <a href='/cct' className="text-inherit flex flex-col m-2 text-center justify-self-center">
                            <img className="flex self-center" src={image1} alt="image for banner" width={'36px'} style={{}} />
                            <p className='text-[#333] font-bold text-[9px] xl:text-[12px] m-0'> นร.ยากจน อนุบาล - ม.ต้น</p>
                            {/* <p className='text-[#333] font-bold text-[9px] xl:text-[12px] m-0'>{`(ทุนเสมอภาค)`}</p> */}
                            <Popover
                              content={
                                <div>
                                  <p>ข้อมูลจำนวนเด็กนักเรียนที่ได้รับความช่วยเหลือใน 6 สังกัด ได้แก่ สพฐ. อปท. ตชด. พศ. สช. และ กทม. ปีการศึกษา 2566</p>
                                </div>
                              }
                              title="ทุนเสมอภาค"
                              placement="bottom">
                              <button style={{ margin: 1, padding: 1, width: '100%' }}>
                                <div className='flex flex-row justify-center'>
                                  <p className='text-[#333] font-bold text-[9px] xl:text-[12px] m-0'>{`(ทุนเสมอภาค)`}</p>
                                  < InfoOutlinedIcon fontSize={'18px'} className='flex self-center mx-1' />
                                </div>
                              </button>
                            </Popover>
                            <p className='text-[#171FD4] font-bold text-md m-0'>{data.alljon.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} คน</p>
                          </a>
                        </div>

                        <div className="flex flex-row bg-[#fff] p-1 mt-0 ">
                          <a href='/vec' className="text-inherit flex flex-col m-2 text-a text-center justify-self-center">
                            <img className="flex self-center" src={image3} alt="image for banner" width={'36px'} style={{}} />
                            <p className='text-[#333] font-bold text-[9px] xl:text-[12px] m-0'> นักเรียน ม.ปลาย/ปวส./ปวช.</p>
                            <Popover
                              content={
                                <div>
                                  <p>ข้อมูลจำนวนเด็กนักเรียนที่ได้รับความช่วยเหลือในปีการศึกษา 2566</p>
                                </div>
                              }
                              title="นวัตกรรมสายอาชีพ"
                              placement="bottom">
                              <button style={{ margin: 1, padding: 1, width: '100%' }}>
                                <div className='flex flex-row justify-center'>
                                  <p className='text-[#333] font-bold text-[9px] xl:text-[12px] m-0'>{`(นวัตกรรมสายอาชีพ)`}</p>
                                  < InfoOutlinedIcon fontSize={'18px'} className='flex self-center mx-1' />
                                </div>
                              </button>
                            </Popover>
                            <p className='text-[#171FD4] font-bold text-md m-0'>{data.vec.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} คน</p>
                          </a>
                        </div>

                        <div className="flex flex-row bg-[#fff] p-1 mt-0 ">
                          <a href='/krt' className="text-inherit flex flex-col m-2 text-a text-center justify-self-center">
                            <img className="flex self-center" src={image2} alt="image for banner" width={'36px'} style={{}} />
                            <p className='text-[#333] font-bold text-[9px] xl:text-[12px] m-0'> นักศึกษาครู ป.ตรี</p>
                            <Popover
                              content={
                                <div>
                                  <p>ข้อมูลจำนวนเด็กนักเรียนที่ได้รับความช่วยเหลือในปีการศึกษา 2566</p>
                                </div>
                              }
                              title="ทุนครูรัก(ษ์)ถิ่น"
                              placement="bottom">
                              <button style={{ margin: 1, padding: 1, width: '100%' }}>
                                <div className='flex flex-row justify-center'>
                                  <p className='text-[#333] font-bold text-[9px] xl:text-[12px] m-0'>{`(ครูรัก(ษ์)ถิ่น)`}</p>
                                  < InfoOutlinedIcon fontSize={'18px'} className='flex self-center mx-1' />
                                </div>
                              </button>
                            </Popover>
                            <p className='text-[#171FD4] font-bold text-md m-0'>{data.krt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} คน</p>
                          </a>
                        </div>

                        <div className="flex flex-row bg-[#fff] p-1 mt-0 ">
                          <a href='/tps' className="text-inherit flex flex-col m-2 text-a text-center justify-self-center">
                            <img className="flex self-center" src={image4} alt="image for banner" width={'36px'} style={{}} />
                            <p className='text-[#333] font-bold text-[9px] xl:text-[12px] m-0'> นักศึกษา ป.ตรี - ป.เอก</p>
                            <Popover
                              content={
                                <div>
                                  <p>ข้อมูลจำนวนเด็กนักเรียนที่ได้รับความช่วยเหลือในปีการศึกษา 2566</p>
                                </div>
                              }
                              title="ทุนพระกนิษฐา"
                              placement="bottom">
                              <button style={{ margin: 1, padding: 1, width: '100%' }}>
                                <div className='flex flex-row justify-center'>
                                  <p className='text-[#333] font-bold text-[9px] xl:text-[12px] m-0'>{`(ทุนพระกนิษฐา)`}</p>
                                  < InfoOutlinedIcon fontSize={'18px'} className='flex self-center mx-1' />
                                </div>
                              </button>
                            </Popover>
                            <p className='text-[#171FD4] font-bold text-md m-0'>{data.tps.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} คน</p>
                          </a>
                        </div>

                        <div className="flex flex-row bg-[#fff] p-1 mt-0 ">
                          <a href='/target_group' className="text-inherit flex flex-col m-2 text-a text-center justify-self-center">
                            <img className="flex self-center" src={image5} alt="image for banner" width={'36px'} style={{}} />
                            <p className='text-[#333] font-bold text-[9px] xl:text-[12px] m-0'> บุคคลทั่วไป</p>
                            <Popover
                              content={
                                <div>
                                  <p> ข้อมูลจำนวนกลุ่มเป้าหมายที่ได้รับความช่วยเหลือในปีการศึกษา 2564 </p>
                                </div>
                              }
                              title="ทุนพัฒนาอาชีพและนวัตกรรมที่ใช้ชุมชนเป็นฐาน"
                              placement="bottom">
                              <button style={{ margin: 1, padding: 1, width: '100%' }}>
                                <div className='flex flex-row justify-center'>
                                  <p className='text-[#333] font-bold text-[9px] xl:text-[12px] m-0'>{`(ทุนพัฒนาอาชีพ)`}</p>
                                  < InfoOutlinedIcon fontSize={'18px'} className='flex self-center mx-1' />
                                </div>
                              </button>
                            </Popover>
                            <p className='text-[#171FD4] font-bold text-md m-0'>{data.target_group.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} คน</p>
                          </a>
                        </div>
                      </>
                    }
                    {
                      props.filter.page === 2 && props.filter.tap == 1 && <>
                        <a href='/oosc' className="text-inherit flex flex-row bg-[#fff] p-1 mt-0 ">
                          <div className="p-2">
                            <img src={image1} alt="image for banner" width={'36px'} style={{ borderRadius: 5 }} />
                          </div>
                          <div className="flex flex-col m-2">
                            <p className='text-[#333] font-bold text-[16px] m-0'>เด็กที่ไม่มีข้อมูลในระบบการศึกษา</p>

                            <Popover
                              content={
                                <div>
                                  <p> เด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา ข้อมูลการสำรวจแผนงาน 20 จังหวัดเสมอภาค ปี 2563</p>
                                </div>
                              }
                              title="แผนงาน 20 จังหวัด"
                              placement="bottom">
                              <button style={{ margin: 1, padding: 1, width: '100%' }}>
                                <div className='flex flex-row'>
                                  <p className='text-[#333] font-bold text-[16px] m-0'>(แผนงาน 20 จังหวัด)</p>
                                  {/* <p className='text-[#333] font-bold text-[9px] xl:text-[12px] m-0'>{`(แผนงาน 20 จังหวัด)`}</p> */}
                                  < InfoOutlinedIcon fontSize={'18px'} className='flex self-center mx-1' />
                                </div>
                              </button>
                            </Popover>
                            <p className='text-[#171FD4] font-bold text-md m-0'>{(data.province20).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} คน</p>
                          </div>
                        </a>

                        <div className="text-inherit flex flex-row bg-[#fff] p-1 mt-0 ">
                          <div className="p-2">
                            <img src={image2} alt="image for banner" width={'36px'} style={{ borderRadius: 5 }} />
                          </div>
                          <div className="flex flex-col m-2">
                            <p className='text-[#333] font-bold text-[16px] m-0'>เด็กที่ไม่มีข้อมูลในระบบการศึกษา</p>
                            <Popover
                              content={
                                <div>
                                  <p> เด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา ข้อมูลการสำรวจแผนงานครูและเด็กนอกระบบ ปี 2563</p>
                                </div>
                              }
                              title="แผนงานครูและเด็กนอกระบบ"
                              placement="bottom">
                              <button style={{ margin: 1, padding: 1, width: '100%' }}>
                                <div className='flex flex-row '>
                                  <p className='text-[#333] font-bold text-[16px] m-0'>(แผนงานครูและเด็กนอกระบบ)</p>
                                  {/* <p className='text-[#333] font-bold text-[9px] xl:text-[12px] m-0'>{`(แผนงาน 20 จังหวัด)`}</p> */}
                                  < InfoOutlinedIcon fontSize={'18px'} className='flex self-center mx-1' />
                                </div>
                              </button>
                            </Popover>
                            <p className='text-[#171FD4] font-bold text-md m-0'>{(data.plan_teaher).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} คน</p>
                          </div>
                        </div>

                        <a href='/ecd' className="text-inherit flex flex-row bg-[#fff] p-1 mt-0 ">
                          <div className="p-2">
                            <img src={imageChildrenOOSC} alt="image for banner" width={'36px'} style={{ borderRadius: 5 }} />
                          </div>
                          <div className="flex flex-col m-2">
                            {/* <p className='text-[#333] font-bold text-[16px] m-0'>เด็กปฐมวัย</p> */}
                            <Popover
                              content={
                                <div>
                                  <p> เด็กปฐมวัยยากจนใน ศพด. ข้อมูลจำนวนเด็กปฐมวัยที่ได้รับความช่วยเหลือในพื้นที่ 20 จังหวัดเสมอภาค ในปีการศึกษา 2563</p>
                                </div>
                              }
                              title="เด็กปฐมวัย"
                              placement="bottom">
                              <button style={{ margin: 1, padding: 1, width: '100%' }}>
                                <div className='flex flex-row '>
                                  <p className='text-[#333] font-bold text-[16px] m-0'>เด็กปฐมวัย</p>
                                  {/* <p className='text-[#333] font-bold text-[9px] xl:text-[12px] m-0'>{`(แผนงาน 20 จังหวัด)`}</p> */}
                                  < InfoOutlinedIcon fontSize={'18px'} className='flex self-center mx-1' />
                                </div>
                              </button>
                            </Popover>
                            <p className='text-[#171FD4] font-bold text-md m-0'>{data.ecd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} คน</p>
                          </div>
                        </a>

                      </>
                    }
                    {
                      props.filter.page === 2 && props.filter.tap == 2 && <>
                        <a href='/tsqp' className="text-inherit flex flex-row bg-[#fff] p-1 mt-0 ">
                          <div className="p-2">
                            <img src={imageSchool} alt="image for banner" width={'36px'} style={{ borderRadius: 5 }} />
                          </div>
                          <div className="flex flex-col m-2">
                            {/* <p className='text-[#333] font-bold text-[16px] m-0'>TSQP</p> */}
                            <Popover
                              content={
                                <div>
                                  <p> โครงการ TSQP ข้อมูลจำนวนโรงเรียนที่เข้าร่วมโครงการ ปีการศึกษา 2564</p>
                                </div>
                              }
                              title="TSQP"
                              placement="bottom">
                              <button style={{ margin: 1, padding: 1, width: '100%' }}>
                                <div className='flex flex-row '>
                                  <p className='text-[#333] font-bold text-[16px] m-0'>TSQP</p>
                                  {/* <p className='text-[#333] font-bold text-[9px] xl:text-[12px] m-0'>{`(แผนงาน 20 จังหวัด)`}</p> */}
                                  < InfoOutlinedIcon fontSize={'18px'} className='flex self-center mx-1' />
                                </div>
                              </button>
                            </Popover>
                            <p className='text-[#171FD4] font-bold text-md m-0'>{(data.tsqp).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} โรงเรียน</p>
                          </div>
                        </a>
                      </>
                    }
                  </div>
                  <div className="flex flex-row flex-row">
                    <Table
                      size="small"
                      bordered
                      scroll={{ x: 800, y: 500 }}
                      style={{ width: '100%' }}
                      pagination={{ pageSize: 10 }}
                      dataSource={data.table.filter(x => {
                        if (x.key === 'ไม่ระบุ') {
                          return (props.filter.page === 1 ? x.sum !== 0 : (x.sum_oosc !== 0 || x.ecd !== 0 || x.tsqp !== 0))
                        } else {
                          return true
                        }
                      })}
                      columns={[
                        ...[
                          {
                            title: handleTitle(props.filter),
                            defaultSortOrder: 'ascend',
                            dataIndex: 'key',
                            key: 'key',
                            width: 70,
                            fixed: 'left',
                            align: 'center',
                            render(text, record) {
                              return {
                                props: {
                                  style: { textAlign: 'left' }
                                },
                                children: <div>
                                  {text === 'ไม่ระบุ' ?
                                    <a
                                      onClick={() => { setModalUnknow(true) }}
                                      className="font-[22px] mb-6 text-gray-800 underline-offset-auto ">
                                      ไม่ระบุ  <InfoIcon style={{ fontsize: 18 }} />
                                    </a> :
                                    (
                                      handleTitle(props.filter) !== 'ตำบล' ?
                                        <a className='m-0 p-0'> {text}</a> :
                                        <p className='m-0 p-0'> {text}</p>
                                    )
                                  }
                                </div>
                              };
                            },
                            sorter: (a, b) => {
                              if (a.key < b.key) {
                                return -1;
                              }
                              if (a.key > b.key) {
                                return 1;
                              }
                              // a must be equal to b
                              return 0;
                            },
                          },
                        ],
                        ...props.filter.page === 1 ? columns_1 : (props.filter.tap === 1 ? columns_2 : columns_3)
                      ]}
                      onRow={(record, rowIndex) => {
                        return {
                          onClick: event => {
                            let type = null
                            if (record.key === 'ไม่ระบุ') return;
                            if (props.filter.view === 'country') {
                              type = 'province'
                              props.updateFilter(record.key, type)
                            } else if (props.filter.view === 'province') {
                              type = 'city'
                              props.updateFilter(record.key, type)
                            } else if (props.filter.view === 'city') {
                              return;
                            }
                          },
                        };
                      }}
                    >

                    </Table>
                  </div>

                </div>
              </div>
              <div className='mb-6'>
                <a
                  onClick={() => { setModal(true) }}
                  className="font-[22px] mb-6 text-gray-800 underline-offset-auto ">
                  <InfoIcon /> แหล่งที่มาของข้อมูล
                </a>
              </div>
            </div>
          </div >
        </div >
      </div >
    </div >
  )
}

export default Sidetab
